@import "../../../styles/funcs.scss";

$anim-time: 120s;

.marquee {
  background: #000;
  position: relative;
  height: getRem(70);
  border-top: getRem(1) solid #fff;
  border-bottom: getRem(1) solid #fff;
  transform: scale(-1, 1);

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    gap: 0;
    white-space: nowrap;
    padding: getRem(16) 0;
    overflow: hidden;
    transform: translate(100%, 0);
    width: getRem(4469);

    & p {
      white-space: nowrap;
      text-transform: uppercase;
      font-size: getRem(30);
      font-family: ChakraPetch-300;
      margin: 0 getRem(30);
      transform: scale(-1, 1);
    }

    & img {
      width: getRem(35);
      user-select: none;
    }
  }

  &__item {
    &:first-child {
      animation: moveMarqueeEl $anim-time infinite linear;
    }

    &:nth-child(2) {
      animation: moveMarqueeEl $anim-time calc($anim-time / 3) infinite linear;
    }

    &:nth-child(3) {
      animation: moveMarqueeEl $anim-time calc($anim-time * 2 / 3) infinite
        linear;
    }

    &:nth-child(4) {
      transform: translate(0, 0);
      animation: moveInitialMarqueeEl $anim-time linear forwards;
    }
  }
}

@keyframes moveMarqueeEl {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(-200%, 0);
  }
}

@keyframes moveInitialMarqueeEl {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-300%, 0);
    display: none;
  }
}
