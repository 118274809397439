@function getRem($size) {
  $remSize: calc($size / 16);
  @return #{$remSize}rem;
}

@mixin breakpoint($point) {
  @if $point == mobile {
    @media screen and (min-width: 360px) {
      @content;
    }
  } @else if $point == tablet {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == tablet-landscape {
    @media screen and (min-width: 1025px) {
      @content;
    }
  } @else if $point == desktop {
    @media screen and (min-width: 1366px) {
      @content;
    }
  } @else if $point == desktopL {
    @media screen and (min-width: 1600px) {
      @content;
    }
  }
}
