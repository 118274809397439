@import "../../../styles/funcs.scss";

.preview {
  height: 100vh;
  position: relative;
  background: url("../../../assets/preview/bg-top-m.png"),
    url("../../../assets/preview/bg-btm-m.png");
  background-repeat: no-repeat;
  background-position: center top, center bottom;
  background-size: 100% 50%;
  min-height: getRem(568);

  @include breakpoint(tablet) {
    background-image: url("../../../assets/preview/bg-top-t.png"),
      url("../../../assets/preview/bg-btm-t.png");
    background-size: 100% 50%;
    min-height: getRem(800);
  }

  @include breakpoint(tablet-landscape) {
    background-image: url("../../../assets/preview/bg-top.png"),
      url("../../../assets/preview/bg-btm.png");
    background-position: center getRem(25), center calc(100% + getRem(25));
    min-height: getRem(768);
    background-size: 100% 50%;
  }

  &__scroll-down {
    width: getRem(65);
    animation: rotateElMobile 20s infinite linear;
    user-select: none;
    position: absolute;
    bottom: getRem(190);
    left: 50%;
    transform: translate(-50%, 0) rotate(0deg);

    @include breakpoint(mobile) {
      width: getRem(90);
      bottom: getRem(170);
    }

    @include breakpoint(tablet) {
      bottom: getRem(275);
      width: getRem(135);
      left: getRem(30);
      transform: translate(0, 0);
      animation: rotateEl 20s infinite linear;
    }

    @include breakpoint(tablet-landscape) {
      bottom: getRem(30);
    }
  }

  &__content {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;

    @include breakpoint(tablet) {
      top: 35%;
    }

    @include breakpoint(tablet-landscape) {
      top: 50%;
    }

    & h1 {
      font-size: getRem(18);
      font-family: ChakraPetch-500;
      max-width: getRem(400);
      margin: 0 auto getRem(20);

      @include breakpoint(mobile) {
        padding: 0 getRem(20);
      }

      & br {
        display: none;

        @include breakpoint(tablet-landscape) {
          display: block;
        }
      }

      @include breakpoint(tablet) {
        font-size: getRem(32);
        padding: 0 getRem(30);
        max-width: getRem(750);
        margin: 0 auto getRem(40);
      }

      @include breakpoint(tablet-landscape) {
        font-size: getRem(40);
        white-space: nowrap;
        max-width: unset;
      }

      & span {
        font-family: ChakraPetch-600;
        background: linear-gradient(359deg, #52baf7 -6.69%, #764bcf 88.4%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
        font-size: getRem(28);
        display: block;

        @include breakpoint(tablet) {
          font-size: getRem(50);
        }

        @include breakpoint(tablet-landscape) {
          background-image: linear-gradient(
            59deg,
            #52baf7 -6.69%,
            #764bcf 88.4%
          );
          font-size: getRem(60);
          display: inline;
        }
      }
    }
  }

  & nav {
    position: absolute;
    bottom: getRem(20);
    right: getRem(5);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: getRem(10);

    @include breakpoint(tablet) {
      bottom: getRem(30);
      margin: 0 2.5%;
      right: getRem(20);
    }

    @include breakpoint(tablet-landscape) {
      bottom: getRem(20);
      width: 70%;
      justify-content: flex-end;
    }

    @include breakpoint(desktop) {
      width: 70%;
    }

    @include breakpoint(desktopL) {
      width: 60%;
    }
  }
}

@keyframes rotateEl {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateElMobile {
  0% {
    transform: translate(-50%, 0) rotate(0deg);
  }
  100% {
    transform: translate(-50%, 0) rotate(360deg);
  }
}
