@import "../../../styles/funcs.scss";

.services {
  padding: getRem(100) 0 0;
  background: url("../../../assets/services/bg/top-t.svg");
  background-size: 100% auto;
  background-position: left top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;

  @include breakpoint(tablet) {
    padding: getRem(130) getRem(30) 0 0;
    background-image: url("../../../assets/services/bg/top-t.svg"),
      url("../../../assets/services/bg/right-t.svg"),
      url("../../../assets/services/bg/shield.svg"),
      url("../../../assets/services/bg/cube.svg"),
      url("../../../assets/services/bg/left.svg");
    background-size: 100% auto, 60% auto, 30% auto, 32.5% auto, 40% auto;
    background-position: left top, right getRem(630), getRem(-50) getRem(200),
      right 79%, left 68%;
  }

  @include breakpoint(tablet-landscape) {
    background-image: url("../../../assets/services/bg/top.svg"),
      url("../../../assets/services/bg/right.svg"),
      url("../../../assets/services/bg/shield.svg"),
      url("../../../assets/services/bg/cube.svg"),
      url("../../../assets/services/bg/left.svg");
    background-size: 90.5% auto, 43% auto, 15.5% auto, 21% auto, 25% auto;
    background-position: left top, right getRem(650), left getRem(340),
      right bottom, left 90%;
  }

  & h1 {
    font-size: getRem(36);
    font-family: ChakraPetch-500;
    text-align: center;
    margin: 0;
    padding: 0 getRem(30);

    @include breakpoint(tablet) {
      font-size: getRem(40);
      padding: 0;
    }

    @include breakpoint(tablet-landscape) {
      white-space: nowrap;
      font-size: getRem(60);
    }

    & span {
      font-family: ChakraPetch-600;
      background: linear-gradient(59deg, #52baf7 -6.69%, #764bcf 88.4%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: getRem(100);
    margin-top: getRem(60);

    @include breakpoint(tablet-landscape) {
      margin-top: getRem(120);
      gap: getRem(140);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;

    &-img {
      background: url("../../../assets/services/left-border-frame.svg"),
        url("../../../assets/services/right-border-frame.svg");
      background-repeat: no-repeat;
      background-size: getRem(25) auto;
      background-position: left center, right center;
      position: relative;
      left: getRem(15);
      z-index: 1;

      @include breakpoint(tablet-landscape) {
        background-size: getRem(63) auto;
        left: 0;
      }
    }
    &-img-inner {
      padding: getRem(20);

      @include breakpoint(tablet-landscape) {
        padding: getRem(70);
      }

      & img {
        width: getRem(120);
        height: getRem(120);

        @include breakpoint(tablet-landscape) {
          width: getRem(260);
          height: getRem(260);
        }
      }
    }

    &-img-bg {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &-content {
      display: flex;
      flex-direction: column;
      //      margin-top: -2.5%;

      & h4 {
        font-family: ChakraPetch-600;
        font-size: getRem(28);
        background: linear-gradient(59deg, #52baf7 -6.69%, #764bcf 88.4%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: fit-content;
        width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        text-align: center;

        @include breakpoint(tablet-landscape) {
          position: static;
          transform: unset;
          bottom: 20%;
          font-size: getRem(40);
          width: fit-content;
          margin: 0;
          margin-left: getRem(40);
          left: 0;
          width: getRem(600);
          text-align: unset;
        }
      }
    }

    &-card {
      width: getRem(520);
      padding: getRem(40);
      backdrop-filter: blur(getRem(10));
      margin: getRem(15) 0;
      background: url("../../../assets/services/line-border.svg");
      background-color: rgba(0, 0, 0, 0.2);
      background-position: center right;
      background-size: auto 100%;
      background-repeat: no-repeat;

      @include breakpoint(tablet-landscape) {
        width: getRem(700);
        margin: getRem(15) 0 getRem(30);
      }

      & p {
        line-height: 1.6;
        font-weight: 300;
        padding-right: getRem(15);

        @include breakpoint(tablet) {
          font-size: getRem(14);
        }

        @include breakpoint(tablet-landscape) {
          font-size: getRem(16);
        }
      }
    }

    &-btn {
      position: relative;
      left: getRem(-70);
      margin: 0 auto;

      @include breakpoint(tablet-landscape) {
        margin: 0 getRem(40) 0 auto;
        left: 0;
      }
    }

    @include breakpoint(tablet-landscape) {
      &:nth-child(2n) {
        flex-direction: row-reverse;

        & .services__item-card {
          background-position: left center;
        }

        & .services__item-btn {
          margin-right: auto;
          margin-left: getRem(40);
        }
      }
    }
  }
}

.services-m {
  padding: getRem(100) 0 0;
  background: url("../../../assets/services/bg/top-t.svg");
  background-size: 100% auto;
  background-position: left top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;

  & h1 {
    font-size: getRem(30);
    font-family: ChakraPetch-500;
    text-align: center;
    margin: 0;
    padding: 0 getRem(30);

    & span {
      font-family: ChakraPetch-600;
      background: linear-gradient(59deg, #52baf7 -6.69%, #764bcf 88.4%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: getRem(100);
    margin-top: getRem(60);
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 getRem(20);

    & h4 {
      font-family: ChakraPetch-600;
      font-size: getRem(24);
      background: linear-gradient(59deg, #52baf7 -6.69%, #764bcf 88.4%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 0 getRem(30);

      text-align: center;
    }

    &.opened {
      & .services-m__card-bg {
        transform: translate(-50%, 0) scale(1, 1);
      }

      & .services-m__border {
        left: 0;
        height: 100%;

        &.right {
          left: auto;
          right: 0;
          height: 100%;
        }
      }
    }

    &-btn {
      opacity: 0;
      transition: 0.7s all;
    }
  }

  &__card {
    padding: getRem(20) getRem(20) getRem(50);
    backdrop-filter: blur(getRem(10));
    margin: getRem(20) 0;
    position: relative;
    transition: 0.7s all;
    width: 100%;
    max-width: getRem(400);

    & img {
      width: getRem(100);
      height: getRem(100);
      margin: 0 auto getRem(20);
      display: block;
    }

    & p {
      line-height: 1.6;
      font-weight: 300;
    }

    &-bg {
      background-color: rgba(0, 0, 0, 0.2);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0) scale(0, 1);
      transition: 0.7s all;
    }

    &-inner {
      position: absolute;
      width: 100%;
      left: 0;
      top: getRem(20);
      z-index: 1;
      opacity: 0;
      transition: 0.7s all;
      padding: 0 getRem(20);
    }
  }

  &__border {
    position: absolute;
    left: 49.5%;
    top: 0;
    width: getRem(2);
    height: 100%;
    background: url("../../../assets/dash-rotated.svg");
    background-position: center calc(50% - getRem(2.5));
    background-size: getRem(2) getRem(20);

    //left: 0%;
    //height: 100%;
    transition: 0.7s all;

    & span {
      position: absolute;
      width: getRem(6);
      height: getRem(6);
      background: url("../../../assets/corner.svg");
      background-size: 100% 100%;
      left: getRem(-2);

      &:first-child {
        top: 0;
      }

      &:last-child {
        bottom: 0;
      }
    }

    &.right {
      left: auto;
      right: 49.5%;
    }
  }
}
