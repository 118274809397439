@import "../../../styles/funcs.scss";

.cooperation {
  padding: getRem(80) 0 getRem(60);
  max-width: getRem(600);
  margin: 0 auto;

  @include breakpoint(tablet) {
    padding: getRem(80) 0 getRem(100);
    max-width: unset;
  }

  @include breakpoint(tablet-landscape) {
    padding: getRem(200) 0 getRem(150);
  }

  &__card {
    width: fit-content;
    padding: getRem(60) getRem(20);
    background: linear-gradient(
      90deg,
      rgba(118, 76, 207, 0.1) 0%,
      rgba(103, 122, 224, 0) 30%,
      rgba(103, 122, 224, 0) 70%,
      rgba(89, 164, 239, 0.1) 100%
    );
    display: flex;
    align-items: flex-end;
    gap: getRem(20);
    margin: 0 auto;
    flex-wrap: wrap;

    & button {
      margin: 0 auto;
    }

    @include breakpoint(mobile) {
      padding: getRem(60) getRem(20);
    }

    @include breakpoint(tablet) {
      padding: getRem(50);
      max-width: 90vw;
      gap: getRem(40);

      & button {
        margin: 0 0 0 auto;
      }
    }

    @include breakpoint(tablet-landscape) {
      flex-wrap: nowrap;
      padding: getRem(60);
      gap: getRem(50);

      & button {
        margin: 0;
      }
    }

    & h3 {
      font-family: ChakraPetch-600;
      line-height: 1.2;
      font-size: getRem(24);
      text-align: center;

      @include breakpoint(mobile) {
        font-size: getRem(30);
      }

      @include breakpoint(tablet) {
        font-size: getRem(34);
        text-align: left;
      }

      @include breakpoint(tablet-landscape) {
        font-size: getRem(48);
      }
    }

    & p {
      font-size: getRem(18);
      font-family: ChakraPetch-500;
      color: #d0d1d1;

      @include breakpoint(tablet) {
        font-size: getRem(24);
      }
    }
  }
}
