@import "../../../styles/funcs.scss";

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: getRem(16) 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(getRem(10));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 100;

  @include breakpoint(tablet) {
    padding: getRem(16) getRem(20);
    justify-content: space-between;
  }

  @include breakpoint(desktop) {
    padding: getRem(16) getRem(120);
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: getRem(2);
    background: url("../../../assets/dash.svg");
    background-position: calc(50% - getRem(2.5)) center;
    background-size: getRem(20) getRem(2);
    left: 0;
    bottom: 0;
  }

  & > a {
    display: flex;
    align-items: center;
  }

  &__desc {
    line-height: 1.6;
    text-align: center;
    display: none;
  }

  &__logo {
    width: getRem(64);

    @include breakpoint(tablet) {
      width: getRem(101);
    }
  }

  &__contacts {
    display: flex;
    align-items: center;

    &-lbl {
      color: var(--white_2, #d0d1d1);
      font-size: getRem(14);
      font-family: ChakraPetch-600;
      text-transform: uppercase;
      margin-right: getRem(10);
      display: none;

      @include breakpoint(tablet) {
        display: block;
      }
    }

    &-box {
      display: flex;
      gap: getRem(10);
      border-radius: getRem(32);
      background: #0e0e10;
      padding: getRem(12) getRem(12) getRem(12) getRem(6);

      @include breakpoint(tablet) {
        gap: getRem(20);
      }
    }

    &-item {
      display: flex;
      align-items: center;

      & span {
        font-family: ChakraPetch-500;
        font-size: getRem(12);

        @include breakpoint(tablet) {
          font-size: getRem(18);
        }
      }

      & img {
        width: getRem(18);

        @include breakpoint(tablet) {
          margin-right: getRem(5);
          width: getRem(26);
        }
      }

      &:last-child {
        & img {
          margin-right: getRem(5);

          @include breakpoint(tablet) {
            margin-right: getRem(10);
          }
        }
      }
    }
  }
}
