@import "../../../styles/funcs.scss";

$btn-border-width: getRem(2);

@mixin base-wrap-bg($width) {
  position: absolute;
  width: calc(100% - $width * 2);
  height: calc(100% - $width * 2);
  left: $width;
  top: $width;
  border-radius: getRem(30);
}

.button {
  border-radius: getRem(30);
  transition: 0.7s all;
  background: none;
  border: none;
  z-index: 1;
  position: relative;
  outline: none;

  &.transparent,
  &.filled {
    font-family: ChakraPetch-700;

    &::before {
      @include base-wrap-bg(0%);
      content: "";
      background: linear-gradient(59deg, #52baf7 -6.69%, #764bcf 88.4%);
      z-index: 1;
    }

    &::after {
      @include base-wrap-bg($btn-border-width);
      content: "";
      opacity: 0;
      background: linear-gradient(59deg, #52baf7 -6.69%, #764bcf -1.93%);
      transition: 0.7s all;
      z-index: 2;
    }
  }

  &.secondary {
    padding: 0 getRem(8);
    font-size: getRem(8);
    height: getRem(24);

    @include breakpoint(tablet) {
      font-size: getRem(16);
      padding: 0 getRem(16);
      height: getRem(48);
    }

    &::before {
      @include base-wrap-bg(0%);
      content: "";
      background: linear-gradient(59deg, #fff -6.69%, #fff 88.4%);
      z-index: 1;
    }

    &::after {
      @include base-wrap-bg(0%);
      content: "";
      opacity: 0;
      background: linear-gradient(59deg, #52baf7 -6.69%, #764bcf 88.4%);
      transition: 0.7s all;
      z-index: 2;
    }

    &:hover {
      &::after {
        opacity: 1;
      }

      & .button__bg {
        background: rgba(0, 0, 0, 0);
      }
    }

    & .button__bg {
      @include base-wrap-bg(getRem(1));
      display: block;
      z-index: 3;
      background: rgba(0, 0, 0, 1);
      transition: 0.7s all;
    }
  }

  &.transparent {
    height: getRem(44);
    padding: 0 getRem(12);
    position: relative;

    @include breakpoint(mobile) {
      padding: 0 getRem(24);
      height: getRem(51);
    }

    &:hover {
      &::after {
        opacity: 1;
      }

      & .button__bg {
        background: rgba(0, 0, 0, 0);
      }
    }

    & .button__bg {
      @include base-wrap-bg($btn-border-width);
      display: block;
      z-index: 3;
      background: rgba(0, 0, 0, 1);
      transition: 0.7s all;
    }
  }

  &.filled {
    height: getRem(62);
    padding: 0 getRem(30);
    font-size: getRem(14);

    @include breakpoint(mobile) {
      font-size: getRem(16);
    }

    &::after {
      @include base-wrap-bg(0%);
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 4;
  }

  &.withArrow {
    & .button__content {
      display: flex;
      align-items: center;

      & .arrow {
        margin-left: getRem(12);
      }
    }

    &:hover {
      & .arrow {
        animation: none;
      }

      & .arrow__line {
        width: getRem(14);
        left: getRem(8);
      }

      & .arrow__point {
        left: getRem(14);
      }
    }
  }
}
