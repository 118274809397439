@import "../../../styles/funcs.scss";

.arrow {
  position: relative;
  width: getRem(30);
  height: getRem(14);
  animation: moveArrow 3s infinite forwards ease-in-out;

  &.active {
    & .arrow__line {
      width: getRem(14);
      left: getRem(8);
    }

    & .arrow__point {
      left: getRem(14);
    }
  }

  &__line {
    position: absolute;
    top: getRem(6);
    left: 0;
    display: block;
    width: getRem(30);
    height: getRem(2);
    background: #fff;
    transition: 0.7s all;
  }

  &__point {
    width: getRem(2);
    height: getRem(2);
    position: absolute;
    background: #fff;
    transition: 0.7s all;

    &:nth-child(1) {
      top: getRem(4);
      left: getRem(26);
    }

    &:nth-child(2) {
      top: getRem(2);
      left: getRem(24);
    }

    &:nth-child(3) {
      top: 0;
      left: getRem(22);
    }

    &:nth-child(4) {
      top: getRem(8);
      left: getRem(26);
    }

    &:nth-child(5) {
      top: getRem(10);
      left: getRem(24);
    }

    &:nth-child(6) {
      top: getRem(12);
      left: getRem(22);
    }
  }
}

@keyframes moveArrow {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(25%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
