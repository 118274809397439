@import "../../../styles/funcs.scss";

.footer {
  background: #000;

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: getRem(50) getRem(20);

    @include breakpoint(tablet) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: getRem(50) 6.25%;
    }

    @include breakpoint(tablet-landscape) {
      justify-content: space-between;
      padding: getRem(40) 6.25%;
      align-items: flex-start;
      flex-wrap: nowrap;
    }
  }

  &__logo {
    width: getRem(159);
    margin-bottom: getRem(30);

    @include breakpoint(tablet) {
      width: getRem(180);
    }
  }

  &__main-box {
    order: 1;

    @include breakpoint(tablet) {
      width: 100%;
    }

    @include breakpoint(tablet-landscape) {
      order: unset;
      width: auto;
    }
  }

  &__main-desc {
    line-height: 1.6;
    text-align: center;
    font-weight: 300;
    font-size: getRem(14);

    & br {
      display: none;
    }

    @include breakpoint(tablet) {
      width: 100%;
      text-align: left;
      margin: 0;
      font-size: getRem(16);
    }

    @include breakpoint(tablet-landscape) {
      font-size: getRem(16);
      text-align: center;
      width: auto;

      & br {
        display: block;
      }
    }

    &:first-child {
      margin: getRem(40) 0 getRem(10);

      @include breakpoint(tablet-landscape) {
        margin: 0;
      }
    }

    & + .footer__main-desc {
      @include breakpoint(desktop) {
        margin-top: getRem(10);
      }
    }
  }

  &__contacts {
    text-align: center;

    @include breakpoint(tablet) {
      width: fit-content;
      display: flex;
      flex-direction: column;
      gap: getRem(10);
      margin-top: getRem(50);
      text-align: left;
    }

    @include breakpoint(tablet-landscape) {
      justify-content: space-between;
      align-items: center;
      width: auto;
      display: block;
      margin: 0;
      gap: 0;
    }

    &-item {
      font-family: ChakraPetch-400;

      & + .footer__contacts-item {
        margin-top: getRem(30);

        @include breakpoint(tablet) {
          margin-top: 0;
        }

        @include breakpoint(tablet-landscape) {
          margin-top: getRem(30);
        }
      }

      &.phone {
        font-size: getRem(18);
      }

      &.social {
        & img {
          width: getRem(26);
          cursor: pointer;
          transition: 0.7s all;

          & + img {
            margin-left: getRem(24);
          }

          &:hover {
            transform: rotate(360deg) scale(1.2);
          }
        }
      }
    }
  }

  &__bottom {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: getRem(23) 6.2% getRem(30);
    overflow: hidden;
    flex-direction: column;
    text-align: center;

    @include breakpoint(tablet) {
      flex-direction: row;
      text-align: left;
      padding: getRem(23) 6.2%;
    }

    & p {
      font-family: ChakraPetch-400;
      font-size: getRem(18);
      margin-bottom: getRem(10);

      @include breakpoint(tablet) {
        margin-bottom: 0;
      }

      &:first-child {
        order: 1;
        margin-top: getRem(10);

        @include breakpoint(tablet) {
          order: unset;
          margin-top: 0;
        }
      }

      & br {
        display: block;

        @include breakpoint(tablet) {
          display: none;
        }
      }
    }

    & a:last-child {
      order: 2;

      @include breakpoint(tablet) {
        order: unset;
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: getRem(2);
      top: 0;
      left: 0;
      background: url("../../../assets/dash.svg");
      background-position: calc(50% - getRem(2.5)) center;
      background-size: getRem(20) getRem(2);
    }
  }
}
