@import "./funcs.scss";

@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: ChakraPetch-300;
  src: url("../fonts/ChakraPetch-Light.ttf");
}

@font-face {
  font-family: ChakraPetch-400;
  src: url("../fonts/ChakraPetch-Regular.ttf");
}

@font-face {
  font-family: ChakraPetch-500;
  src: url("../fonts/ChakraPetch-Medium.ttf");
}

@font-face {
  font-family: ChakraPetch-600;
  src: url("../fonts/ChakraPetch-SemiBold.ttf");
}

@font-face {
  font-family: ChakraPetch-700;
  src: url("../fonts/ChakraPetch-Bold.ttf");
}

$mobile: 360px;
$tablet: 768px;
$tablet-landscape: 1025px;
$desktop: 1366px;
$desktopL: 1600px;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 16px;
  overflow-x: hidden;
  scroll-behavior: smooth;

  @include breakpoint(tablet-landscape) {
    font-size: calc(16 * 100vw / 1300);
    width: calc(0vw + 100%);
  }

  @include breakpoint(desktop) {
    font-size: calc(16 * 100vw / 1600);
    width: calc(0vw + 100%);
  }

  @include breakpoint(desktopL) {
    font-size: calc(16 * 100vw / 1920);
    width: calc(0vw + 100%);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("../assets/background-texture.png"),
    #1e1f21 0% 0% / 100px 100px repeat;
  background-color: #121314;
  font-family: Poppins;
  font-size: getRem(16);
  color: #fff;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img {
  height: auto;
}

button {
  cursor: pointer;
  color: #fff;
}

a {
  text-decoration: none;
  color: inherit;
}
